import React, { useContext, useEffect, useState } from 'react'
import { PageProps } from 'gatsby'
import { context } from '~/layouts'
import { Head, SmallPage, Footer } from '~/components'
import Banner from '~/assets/img/Hero-404.jpg'
import OutlinedText from '~/assets/svg/Erro-404.svg'
import OutlinedTextMobile from '~/assets/svg/Erro-404-mobile.svg'

const NotFound = ({ location }: PageProps) => {
  const { setLoading } = useContext(context)
  const [ready, setReady] = useState(false)
  useEffect(() => {
    setLoading(false)
    setReady(true)
  }, [])

  return ready ? (
    <>
      <Head
        location={location}
        title="Página não encontrada - Atendimento Alínea"
      />
      <SmallPage
        banner={Banner}
        title="Página não encontrada"
        outlinedText={OutlinedText}
        outlinedTextMobile={OutlinedTextMobile}
        message="A página que você tentou acessar não existe ou está fora do ar temporariamente. Verifique o link que você utilizou e tente novamente."
        buttonLabel="Ir para home"
      />
      <Footer />
    </>
  ) : (
    <Head location={location} />
  )
}

export default NotFound
